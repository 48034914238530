import { render, staticRenderFns } from "./allMaterialReport2.vue?vue&type=template&id=24c18d28&scoped=true&"
import script from "./allMaterialReport2.vue?vue&type=script&lang=js&"
export * from "./allMaterialReport2.vue?vue&type=script&lang=js&"
import style0 from "./allMaterialReport2.vue?vue&type=style&index=0&id=24c18d28&prod&scoped=true&lang=css&"
import style1 from "./allMaterialReport2.vue?vue&type=style&index=1&id=24c18d28&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24c18d28",
  null
  
)

export default component.exports