<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">
              {{ $t('MENU.REPORT.FINANCE_REPORT.LAST_MATERIALS') }}
            </h3>
          </div>
        </div>
        <div class="card-body">
          <v-alert type="success">
            <!-- <div v-for="item2 in getStoreList" :key="item2.id">
              {{ item2.name }}
            </div> -->

            {{ tabtitle }} skladida {{ from_date }} dan {{ to_date }} gacha
            materiallar qoldig'i
          </v-alert>
          <div>
            <div class="row p-0">
              <div class="col-3">
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="from_date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Sanadan"
                      dense
                      :value="from_date | dateFilter"
                      append-icon="event"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="from_date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn small color="error" @click="modal = false"
                      >Bekor qilish</v-btn
                    >
                    <v-btn
                      small
                      color="success"
                      @click="
                        $refs.dialog.save(from_date)
                        testdate()
                      "
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </div>

              <div class="col-3">
                <v-dialog
                  ref="dialog2"
                  v-model="modal2"
                  :return-value.sync="to_date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Sanagacha"
                      dense
                      :value="to_date | dateFilter"
                      append-icon="event"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="to_date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn small color="error" @click="modal2 = false"
                      >Bekor qilish</v-btn
                    >
                    <v-btn
                      small
                      color="success"
                      @click="
                        $refs.dialog2.save(to_date)
                        testdate()
                      "
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </div>
            </div>
            <b-tabs content-class="mt-3" fill v-model="tabIndex">
              <b-tab
                v-model="tabName"
                v-for="i in getStoreList"
                :key="i.id"
                :title="i.name"
                ref="tabActionbtn"
                @click="tabAction(i.id, i.name)"
              >
                <!-- <div>
                  <v-data-table
                    :headers="headers"
                    :items="getStoreList2"
                    hide-default-footer
                    class="elevation-1"
                  >
                  
                  </v-data-table>
                </div> -->
                <div style="overflow: scroll">
                  <table>
                    <tr>
                      <th rowspan="2">#</th>
                      <th rowspan="2">ID</th>
                      <th rowspan="2">Nomi</th>
                      <th rowspan="2">O'lcham</th>
                      <th colspan="2">Hisobot boshi</th>
                      <th colspan="2">Kirim</th>
                      <th colspan="2">Chiqim</th>
                      <th colspan="2">Transfer Kirim (IM)</th>
                      <th colspan="2">Transfer Chiqim (EX)</th>
                      <th colspan="2">Hisobot oxiri</th>
                    </tr>

                    <tr>
                      <td v-for="item in 12" :key="item">
                        <span v-if="item % 2 !== 0"> Soni </span>
                        <span v-else> Summa </span>
                      </td>
                    </tr>

                    <tr v-for="item in list" :key="item.id">
                      <td>
                        {{ item.index }}
                      </td>
                      <td>
                        {{ item.id }}
                      </td>
                      <td>
                        {{ item.name }}
                      </td>
                      <td>
                        {{ item.measurement_name }}
                      </td>

                      <!-- Hisobot boshi -->
                      <td>
                        {{ item.reports_final.total_start.amount_to_date }}
                      </td>

                      <td>
                        {{ item.reports_final.total_start.total_to_date }}
                      </td>

                      <!-- Kirim -->
                      <td>
                        {{
                          item.reports_final.range_date_import_values
                            .amount_to_date
                        }}
                      </td>

                      <td>
                        {{
                          item.reports_final.range_date_import_values
                            .total_to_date
                        }}
                      </td>

                      <!-- Chiqim -->
                      <td>
                        {{
                          item.reports_final.range_date_export_values
                            .amount_to_date
                        }}
                      </td>

                      <td>
                        {{
                          item.reports_final.range_date_export_values
                            .total_to_date
                        }}
                      </td>

                      <!-- Transfer IM -->
                      <td>
                        {{
                          item.reports_final.range_date_im_transfer_values
                            .amount_to_date
                        }}
                      </td>

                      <td>
                        {{
                          item.reports_final.range_date_im_transfer_values
                            .total_to_date
                        }}
                      </td>

                      <!-- Transfer EX -->
                      <td>
                        {{
                          item.reports_final.range_date_ex_transfer_values
                            .amount_to_date
                        }}
                      </td>

                      <td>
                        {{
                          item.reports_final.range_date_ex_transfer_values
                            .total_to_date
                        }}
                      </td>

                      <!-- Hisobot oxirgi -->
                      <td>
                        {{ item.reports_final.total_end.amount_to_date }}
                      </td>

                      <td>
                        {{ item.reports_final.total_end.total_to_date }}
                      </td>
                    </tr>
                  </table>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {
      tabtitle: '',
      to_date: new Date().toISOString().substr(0, 10),
      from_date: new Date().toISOString().substr(0, 10),
      modal: false,
      modal2: false,
      tabIndex: '',
      test: 11,
      tabName: '',
      list: [],
      total_to_date: 0
    }
  },

  created() {
    const data = {
      to_date: new Date().toISOString().substr(0, 10),
      from_date: new Date().toISOString().substr(0, 10),
      params: 11
    }
    this.$store.dispatch('storeList')

    this.$store.dispatch('materialReport2', data)
  },
  computed: {
    getStoreList2() {
      const data = this.$store.state.accounts.storeList2

      return data
    },
    getStoreList() {
      return this.$store.state.accounts.storeList.results
    }
  },
  watch: {
    getStoreList2: function (val) {
      this.list = []
      const data = {
        id: '',
        measurement_name: '',
        reports_final: {
          from_date_import_values: {
            amount_to_date: '',
            total_to_date: 0
          },
          to_date_import_values: {
            amount_to_date: '',
            total_to_date: 0
          },
          range_date_import_values: {
            amount_to_date: '',
            total_to_date: 0
          },
          from_date_export_values: {
            amount_to_date: '',
            total_to_date: 0
          },
          to_date_export_values: {
            amount_to_date: '',
            total_to_date: 0
          },
          range_date_export_values: {
            amount_to_date: '',
            total_to_date: 0
          },
          from_date_im_transfer_values: {
            amount_to_date: '',
            total_to_date: 0
          },
          to_date_im_transfer_values: {
            amount_to_date: '',
            total_to_date: 0
          },
          range_date_im_transfer_values: {
            amount_to_date: '',
            total_to_date: 0
          },
          from_date_ex_transfer_values: {
            amount_to_date: '',
            total_to_date: 0
          },
          to_date_ex_transfer_values: {
            amount_to_date: '',
            total_to_date: 0
          },
          range_date_ex_transfer_values: {
            amount_to_date: '',
            total_to_date: 0
          },
          total_start: {
            amount_to_date: '',
            total_to_date: 0
          },
          total_end: {
            amount_to_date: '',
            total_to_date: 0
          }
        },
        name: '',
        code: '',
        group: '',
        measurement: ''
      }
      val.forEach((element) => {
        if (element.reports_final.total_start.total_to_date !== undefined) {
          for (let i in element.reports_final) {
            data.reports_final[i].total_to_date += parseInt(
              element.reports_final[i].total_to_date.replace(/[ ,.]/g, ''),
              10
            )
          }
        }
      })
      for (let i in data.reports_final) {
        data.reports_final[i].total_to_date =
          data.reports_final[i].total_to_date.toLocaleString('es-US')
      }
      this.list = [...val]
      this.list.push(data)

      // val.push(data);
    }
  },
  filters: {
    mask: (val) => {
      if (val !== undefined) {
        return val.toLocaleString('es-US')
      }
    }
  },
  methods: {
    testdate() {
      const data = {
        to_date: this.to_date,
        from_date: this.from_date,
        params: this.test
      }

      this.$store.dispatch('materialReport2', data)
    },
    tabAction(value, tabName) {
      this.tabtitle = tabName
      this.test = value
      const data = {
        to_date: this.to_date,
        from_date: this.from_date,
        params: value
      }
      this.$store.dispatch('materialReport2', data)
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.REPORT.TITLE') },
      { title: this.$t('MENU.REPORT.FINANCE_REPORT.TITLE') },
      { title: this.$t('MENU.REPORT.FINANCE_REPORT.LAST_MATERIALS') }
    ])
  }
}
</script>

<style scoped>
tr:nth-child(even) {
  background-color: #f2f2f2 !important;
}
table {
  border-collapse: collapse !important ;
  width: 100% !important;
  text-align: center;
  overflow-x: scroll !important;
}
th,
td {
  padding: 0.4rem 2.25rem !important;
  text-align: center;
  border: 1px solid #ccc;
}
.nav /deep/ .nav-link:hover:not(.disabled),
.nav /deep/ .nav-link {
  background-color: #1c1c1c0b !important;
  font-size: 12px !important;
  color: #000000 !important;

  text-transform: uppercase !important;
}
.nav /deep/ .nav-link:hover:not(.disabled),
.nav /deep/ .nav-link.active {
  background-color: rgba(0, 0, 0, 0.228) !important;
  font-size: 12px !important;
  color: #000000 !important;

  text-transform: uppercase !important;
}
.theme--light
  /deep/
  .v-data-table__wrapper
  /deep/
  tbody
  /deep/
  tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.105) !important;
}
p {
  margin-bottom: 0;
}
.header__line {
  border-left: 1px solid rgba(0, 0, 0, 0.18);
  /* margin-bottom: 16px; */
  margin: 0;
  min-height: 100%;
  text-align: center;
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
}
.header__line2 {
  border-left: unset;
  border-right: 1px solid rgba(0, 0, 0, 0.18);
}
.cell__line {
  border-left: 1px solid rgba(0, 0, 0, 0.18);
  height: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
}
.cell__line2 {
  border-left: unset;
  border-right: 1px solid rgba(0, 0, 0, 0.18);
}
.v-input /deep/ .v-input__prepend-outer {
  display: none !important;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  /* font-size: 1rem !important; */
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  /* font-size: 1rem !important; */
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
<style scoped>
.navi /deep/ .navi-item /deep/ .navi-link {
  padding: 0.75rem 1rem !important;
}
.v-select /deep/ .v-select-toggle {
  padding: 0.8rem 1rem !important;
  background-color: #f3f6f9 !important;
}
.v-select /deep/ .v-dropdown-container ul {
  height: 400px;
  overflow-y: scroll;
}
</style>
